import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Image from "gatsby-image"
import has from "lodash.has"
import get from "lodash.get"
import BackgroundImage from "gatsby-background-image"
import Team from "../components/Team"
import CarouselBg from "../components/CarouselBg"
import { Helmet } from "react-helmet"

export default function AboutPage({ data }) {
  return (
    <Layout>
      <Helmet>
      <title>
         Our Team | Softful - Software company Auckland NZ
        </title>
      </Helmet>
      <div>
        <CarouselBg>
          {" "}
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-right in-pic-height">
                <div className="in-text-holder">About Us</div>
                <div
                  className="in-pic-1 in-pic-height"
                  style={{ float: "right" }}
                >
                  <Image
                    style={{ height: "100%" }}
                    fluid={data.in_pic_5.sharp.fluid}
                  />
                </div>
              </div>
            </div>
          </div>
        </CarouselBg>
        <div className="breadcrums">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Link to="/">Home</Link> - About Us
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-holder">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <p>
                Softful is passionate about making software and just as
                passionate about making software that works for your business.
              </p>
              <p>
                With 20 years of combined industry expertise within our team, we
                have mastered modern software technologies for all sorts of
                platforms such as Web, Android, iOS and Windows.
              </p>
              <p>
                Alongside this software expertise, we offer business consulting
                to deliver the right tools for your small or medium sized
                business. Whether you want a new website to boost sales, need a
                mobile app to stay connected with your customers, or keen to
                automate your daily routines, Softful will develop a customised
                solution to meet your specific requirements.
              </p>
              <p>
                The first step on the path to success is a free consultation.
                Ask for one today.
              </p>
            </div>
          </div>
          <Team />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  {
    in_pic_5: file(publicURL: { regex: "/in_pic_5.png/" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 641) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
